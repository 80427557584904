'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var omar = void 0;
document.addEventListener("DOMContentLoaded", function () {
    $(document).foundation();
    Config.image_url = '/images/OMAR/';
    Config.listIcon = 'arrow';
    Config.mapStyleType = 'prestige';
    omar = new Omar();
});

var Omar = function () {
    function Omar() {
        _classCallCheck(this, Omar);

        this.content = $(document.getElementsByClassName('content'));
        this.main = $('.main');
        this.hamburger = $('.hamburger');
        this.more = $('.more');
        this.pubHome = $('#campaign-home');
        this.uiCookies = $('.ui-cookies');
        this.sliderHolder = $('.slider-holder');
        this.tabletWidth = 1023;
        this.phoneWidth = 641;
        console.log(BrowserInfo);
        BrowserInfo.isIE || BrowserInfo.isEdge || BrowserInfo.isChrome ? this.content.css('display', 'block') : this.content.fadeIn(100);
        BrowserInfo.isIE ? this.debugIE() : null;
        this.init();
        MultiSelect.init();
        ChosenTouch.init();
    }

    _createClass(Omar, [{
        key: 'init',
        value: function init() {
            var _this = this;

            AOS.init({
                offset: 400,
                duration: 500,
                easing: 'ease-out-sine',
                delay: 100
            });
            window.onresize = null;
            if (this.main && this.main != null && $('.main').length > 0) {
                var CLASSES = $('.main').attr('class').split(/\s+/),
                    PAGE = [],
                    i = 0;
                for (i = 0; i < CLASSES.length; i++) {
                    if (CLASSES[i] != 'main') {
                        PAGE.push(CLASSES[i]);
                    }
                }
                PAGE.length == 1 ? Config.page = PAGE[0] : Config.page = PAGE.join('');
                this.setNav();
                this.initPage(Config.page);
                this.initModals();
            }
            if (this.pubHome.css('display')) {
                var img = new Image();
                img.onload = function () {
                    if (BrowserInfo.isMobile) _this.resizePub();
                    _this.pubHome.css({
                        'margin-top': '-' + (_this.pubHome.height() / 2 + 1) + 'px',
                        'display': 'block'
                    });
                    _this.pubHome.removeClass('hidden');
                };
                img.src = this.pubHome.find('img').attr('src');
            }
            this.bindUIActions();
        }
    }, {
        key: 'resizePub',
        value: function resizePub() {
            $('.ui-ad-image').css('max-height', '90vh');
            $('#close-ad').css({
                'right': '-13px',
                'top': '-13px',
                'width': '25px',
                'height': '25px'
            });
            $('#close-ad i').css({
                'font-size': '1em',
                'top': '0'
            });
        }
    }, {
        key: 'debugIE',
        value: function debugIE() {
            $('#slides, .right-block').addClass('ie');
        }
    }, {
        key: 'bindUIActions',
        value: function bindUIActions() {
            var _this2 = this;

            $(document.getElementsByClassName('chosen-select')).chosen({
                search_contains: true
            });
            this.uiCookies.on('click', function () {
                $(document.getElementById('ui-id-cookies')).css({
                    '-webkit-transform': 'translateY(100%)',
                    '-ms-transform': 'translateY(100%)',
                    'transform': 'translateY(100%)'
                });
            });
            $('.reveal-overlay .close-button').on("click", function () {
                $('.reveal-overlay').removeClass('fdn-reveal');
            });
            $('p.read-more').readmore({
                speed: 75,
                lessLink: '<a href="#">Fermer</a>',
                moreLink: '<a href="#">Lire la suite</a>'
            });
            $('.clear-filters').on("click", function (e) {
                e.preventDefault();
                _this2.resetForm();
            });
            $('.is-accordion-submenu-parent').on("click", function () {
                $('ul.menu.nested').each(function () {
                    $(this).removeClass('is-active').css('display', 'none');
                });
                var nestedMenu = $(this).find('ul.menu.nested'),
                    menuPadding = 20;
                nestedMenu.addClass('is-active').css('display', 'block');
                if (nestedMenu.offset().left + nestedMenu.width() > window.innerWidth) {
                    nestedMenu.css('margin-left', '-' + (nestedMenu.offset().left + nestedMenu.width() - window.innerWidth + menuPadding * 2) + 'px');
                }
            });
        }
    }, {
        key: 'initPage',
        value: function initPage(page) {
            var _this3 = this;

            var isAgence = function isAgence() {
                $('.ui-pagination:first-child').addClass('current');
                $('.ui-pagination-vendu:first-child').addClass('current');
                _this3.loadScript();
            };
            var isList = function isList() {
                $('.search-bar').css('display', 'block');
            };
            var self = this,
                pages = {
                'home': function home() {
                    _this3.initHome();
                    if (!BrowserInfo.isIE) {
                        _this3.initSlides({
                            hashchange: false,
                            play: 8000,
                            animation_speed: 1000,
                            pagination: false
                        });
                    }
                },
                'bien': function bien() {
                    $(document.getElementById('choco-gallery')).Chocolat({
                        imageSize: 'contain',
                        loop: true,
                        firstImage: 0,
                        imageSelector: '.chocolat-image',
                        enableZoom: false
                    });
                    $('.gallery-img').each(function () {
                        $(this).on('click', function () {
                            var src = $(this).css('background-image');
                            var srcSafari = src.replace('url(', '').replace(')', '').replace(/\"/gi, "");
                            setTimeout(function () {
                                $('.chocolat-img').attr('src', BrowserInfo.isSafari || BrowserInfo.isEdge ? srcSafari : self.extractText(src));
                            }, 100);
                        });
                    });
                    if (!BrowserInfo.isIE) {
                        _this3.initSlides({
                            hashchange: false
                        });
                    } else {
                        $('#slides').css({
                            'background': 'url(' + $('#slides img:first-child').attr('src') + ')',
                            'background-position': '50% 50%',
                            'background-repeat': 'no-repeat',
                            'background-size': 'cover'
                        });
                    }
                    $(document.getElementsByClassName('slidiv')).each(function (i) {
                        if (i > 0) {
                            $(this).addClass('slide-in');
                        }
                    });
                    $('#detail-btns li:first-child a').addClass('selected');
                    $('#detail-btns li a').each(function () {
                        var $link;
                        $(this).on("click", function (e) {
                            e.preventDefault();
                            $link = $(this).attr('href');
                            $(document.getElementsByClassName('slidiv')).each(function () {
                                $(this).addClass('slide-out');
                            });
                            $($link).removeClass('slide-out').addClass('slide-in');
                            setTimeout(function () {
                                $($link).removeClass('slide-in');
                            }, 0);
                            $('#detail-btns li a').each(function () {
                                $(this).removeClass('selected');
                            });
                            $(this).addClass('selected');
                        });
                    });
                    $('a#down').on("click", function (e) {
                        e.preventDefault();
                        $('html, body').animate({
                            scrollTop: $("#description").offset().top
                        }, 700);
                    });
                },
                'list': isList(),
                'pn': isList(),
                'usersusr-file': function usersusrFile() {
                    isAgence();
                },
                'agences': function agences() {
                    isAgence();
                },
                'usersagence': function usersagence() {
                    isAgence();
                },
                'generic': function generic() {
                    $('.equal').css('height', $('.block-text').height() + 100);
                    window.onresize = _this3.equalize;
                },
                'default': function _default() {}
            };
            return (pages[page] || pages['default'])();
        }
    }, {
        key: 'equalize',
        value: function equalize() {
            $('.equal').css('height', $('.block-text').height() + 100);
        }
    }, {
        key: 'initModals',
        value: function initModals() {
            var TRIGGERS = [$('*[data-open="alert"]'), $('*[data-open="resp"]'), $('*[data-open="sold"]'), $('*[data-open="reco"]'), $('*[data-open="share"]'), $('*[data-open="write_rating"]')],
                MODALS = [$('#alert.reveal').parent(), $('#resp.reveal').parent(), $('#sold.reveal').parent(), $('#reco.reveal').parent(), $('#share.reveal').parent(), $('#write_rating.reveal').parent()],
                elem,
                i = 0;
            $(document.getElementsByClassName('ui-resp')).each(function () {
                elem = $('#' + $(this).data('open'));
                this.initModal($(this), elem.parent());
            });
            for (i = 0; i < MODALS.length; i++) {
                this.initModal(TRIGGERS[i], MODALS[i]);
            }
        }
    }, {
        key: 'initModal',
        value: function initModal(trigger, modal) {
            trigger.on("click", function () {
                modal.addClass('fdn-reveal');
            });
        }
    }, {
        key: 'extractText',
        value: function extractText(str) {
            var ret = "";
            if (/"/.test(str)) {
                ret = str.match(/"(.*?)"/)[1];
            } else {
                ret = str;
            }
            return ret;
        }
    }, {
        key: 'initHome',
        value: function initHome() {
            var filterDrop = $('.filter-property').find('ul.custom-dropdown');
            BrowserInfo.isIE ? $(document.getElementById('main-nav')).css('display', 'block') : $(document.getElementById('main-nav')).fadeIn();
            $(document.getElementById('main-search')).css('display', 'block');
            filterDrop.css('top', -filterDrop.height() + 'px');
            $('.top').addClass('active');
            $('.filter-property').attr('novalidate', '');
            if (!this.sliderHolder.css('display')) {
                if ($('video').css('display')) {
                    window.onresize = this.videoResize;
                    this.videoResize();
                } else {
                    window.onresize = this.imgResize;
                    this.imgResize();
                }
            }
            $(document).on("scroll", this.onScroll);
            $('a[href^="#"]').on('click', function (e) {
                var _this4 = this;

                e.preventDefault();
                $(document).off("scroll");
                $('a').each(function () {
                    $(this).removeClass('active');
                });
                $(this).addClass('active');
                var target = this.hash,
                    $target = $(target);
                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top + 2
                }, 500, 'swing', function () {
                    window.location.hash = target;
                    $(document).on("scroll", _this4.onScroll);
                });
            });
            this.checkOffset();
            window.addEventListener('scroll', this.checkOffset);
            if (this.sliderHolder.css('display')) {
                this.content.css('top', 800);
            }
            $(document.getElementById('down')).on("click", function (e) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: $(document.getElementById('annonces')).offset().top
                }, 700);
            });
        }
    }, {
        key: 'initSlides',
        value: function initSlides(obj) {
            if ($(document.getElementById('slides')).css('display')) {
                try {
                    $(document.getElementById('slides')).superslides(obj);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }, {
        key: 'resetForm',
        value: function resetForm() {
            $(document.getElementsByClassName('chosen-select')).val('').trigger("chosen:updated");
            var searchBudgetMin = $(document.getElementById('property_search_budgetMin')),
                searchBudgetMax = $(document.getElementById('property_search_budgetMax'));
            var FIELDS = [searchBudgetMin, searchBudgetMax];
            for (var i = 0; i < FIELDS.length; i++) {
                FIELDS[i].val('');
            }
            $("#submit").trigger('click');
        }
    }, {
        key: 'checkOffset',
        value: function checkOffset() {
            if (window.pageYOffset > 0) {
                window.removeEventListener('scroll', this.checkOffset);
                $('.property-search').css({
                    '-webkit-transform': 'translateY(0%)',
                    '-ms-transform': 'translateY(0%)',
                    'transform': 'translateY(0%)'
                });
            }
        }
    }, {
        key: 'onScroll',
        value: function onScroll() {
            var scrollPos = $(document).scrollTop();
            $('#menu-center a').each(function () {
                var currLink = $(this),
                    refElement = $(currLink.attr('href'));
                if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() >= scrollPos) {
                    $('#menu-center ul li a').removeClass('active');
                    currLink.addClass('active');
                } else {
                    currLink.removeClass('active');
                }
                if ($('a.top').hasClass('active')) {
                    $('a.ann').removeClass('active');
                }
            });
        }
    }, {
        key: 'setNav',
        value: function setNav() {
            var _this5 = this;

            var pathArray = window.location.pathname.split('/');
            var url = window.location.href,
                lang = pathArray[1];
            if (Config.page != 'home') {
                window.onresize = this.menuResize;
                $(document.getElementById('main-nav')).addClass('clearfix nb');
                setTimeout(function () {
                    window.innerWidth > _this5.tabletWidth ? $(document.getElementById('main-nav')).slideDown() : $(document.getElementById('main-nav')).css('display', '');
                }, 0);
            }
            $('.top-nav ul li a').each(function () {
                if (url === Config.base_url + '/' + lang + $(this).data('id')) {
                    $(this).addClass('active');
                    if ($(this).parent().hasClass('is-accordion-submenu-parent')) {
                        $(this).parent().parent().parent().find('a:first').css('color', $(this).css('color'));
                    }
                }
            });
        }
    }, {
        key: 'loadScript',
        value: function loadScript() {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCFhZbc0zfGusLksxcmw7ap_OuOsBDq2SM&language=fr';
            document.body.appendChild(script);
        }
    }, {
        key: 'imgResize',
        value: function imgResize() {
            var background = $('.background'),
                hDoc = window.innerHeight,
                minResize = 500;
            hDoc > minResize ? background.css('height', hDoc + 'px') : background.css('height', minResize + 'px');
        }
    }, {
        key: 'videoResize',
        value: function videoResize() {
            var hDoc = window.innerHeight,
                minResize = 500;
            hDoc > minResize ? this.content.css('top', hDoc) : this.content.css('top', minResize);
        }
    }, {
        key: 'menuResize',
        value: function menuResize() {
            if (window.innerWidth > this.tabletWidth) {
                var menuLeft = $('.off-canvas.position-left'),
                    wrapperInner = $('.off-canvas-wrapper-inner');
                if (menuLeft.hasClass('is-open')) menuLeft.removeClass('is-open');
                if (wrapperInner.hasClass('is-off-canvas-open is-open-left')) wrapperInner.removeClass('is-off-canvas-open is-open-left');
            }
        }
    }, {
        key: 'initLocal',
        value: function initLocal(lng, lat, add_marker) {
            var mapOptions = {
                center: new google.maps.LatLng(lng, lat),
                zoom: add_marker === 1 ? 16 : 14,
                scrollwheel: false,
                streetViewControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN]
                },
                styles: Config.mapStyles[Config.mapStyleType]
            };
            var map = new google.maps.Map(document.getElementById('carte'), mapOptions);
            if (add_marker === 1) {
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(lng, lat)
                });
                marker.setMap(map);
            }
            //GoogleMap.searchNearby(map); // Pour activer les POI
        }
    }, {
        key: 'displayProperties',
        value: function displayProperties(data, id_display) {
            var _this6 = this;

            $.each(data, function (key, value) {
                var transac,
                    alertStyle = 'style="margin-bottom: 10px;"';
                var price_drop_content = !value.price_drop ? '' : '<li><span class="label bg-primary">' + value.price_drop + '</span></li>';
                var exclu_content = !value.exclu ? '' : '<div class="label alert" ' + alertStyle + '>' + value.exclu + '</div>';
                switch (value.type_transac) {
                    case 'vente':
                        transac = value.sold_stc != '' ? '<span class="label bg-violet">' + value.sold_stc + '</span>' : '<span class="label bg-bleu">' + 'Vente</span>';
                        break;
                    case 'location':
                        transac = '<span class="label bg-vert">' + 'Location</span>';
                        break;
                    case 'vendu':
                        transac = '<span class="label bg-rouge">' + 'Vendu</span>';
                        break;
                    default:
                        transac = '';
                }
                $("#" + id_display).append('<div id="' + value.id + '" class="large-12 columns no-marg annonce ab">' + '<div class="left-block">' + '<a href="' + value.link + '">' + '<div class="cover"></div>' + (value.image ? '<div class="ah-image" style="background-image: url(' + value.image + ');background-repeat: no-repeat;background-position: 50% 50%;background-size: cover;"></div>' : '<div class="ah-image no-img"><i class="fi-camera"></i></div>') + '</a>' + '</div>' + '<div class="right-block">' + '<div class="rb-inner">' + exclu_content + transac + '<h2 class="h3">' + _this6.titleCase(value.city) + ' ' + value.rooms + '<span class="h5">' + _this6.titleCase(value.city) + '</span></h2>' + '<div class="details">' + (price_drop_content != '' ? '<div>' + price_drop_content + '</div>' : '') + '<p>' + value.price + '</p>' + '</div>' + '<a class="button btn-outline" href="' + value.link + '">En savoir plus</a>' + '</div>' + '</div>' + '</div>');
            });
        }
    }, {
        key: 'titleCase',
        value: function titleCase(str) {
            str = str.toLowerCase();
            str = str.split(' ');
            var i;
            for (i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
            }
            return str.join(' ');
        }
    }, {
        key: 'bindCallIntention',
        value: function bindCallIntention(event_url) {
            var _this7 = this;

            $('.ui-resp').on('click', function (e) {
                console.log('hi', event_url);
                e.preventDefault();
                var aid = $(_this7).attr('id');
                var call_int = aid.indexOf('resp-b-') == 0 || aid.indexOf('resp-t-') == 0;
                aid = aid.substring(7);
                if (call_int) {
                    event_url = event_url.replace('VALUE', aid);
                    $.ajax({
                        url: event_url
                    });
                }
                var iframe = $('#resp-iframe-' + aid);
                if (iframe.data('src')) iframe.prop('src', iframe.data('src')).data('src', false);
            });
        }
    }]);

    return Omar;
}();
